import { Routes,Route } from "react-router-dom";
import IndexPage from "../pages/IndexPage";
import RoutesPage from "../pages/RoutesPage";
import SettingsPage from "../pages/SettingsPage";
import VehiclesPage from "../pages/VehiclesPage";
import VehicleUsersPage from "../pages/VehicleUsersPage";
import AddVehiclePage from "../pages/AddVehiclePage";
import EditVehiclePage from "../pages/EditVehiclePage";
import FuelCalculatorPage from "../pages/FuelCalculatorPage";
import SingleCarPage from "../pages/SingleCarPage";
import LoginPage from '../pages/auth/LoginPage'
import AdminsPage from "../pages/AdminsPage";
import AddUserPage from "../pages/AddUserPage";
import EditUserPage from "../pages/EditUserPage";
import FuelReportsPage from "../pages/FuelReportsPage";
import EditIssuingPage from '../pages/EditIssuingPage';
function Web() {
  return (
     <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/home" element={<IndexPage />} />
        <Route path="/routes" element={<RoutesPage />} />
        <Route path="/settings" element={<SettingsPage/>} />
        <Route path="/vehicles" element={<VehiclesPage/>} />
        <Route path="/vehicle_users" element={<VehicleUsersPage/>} />

        <Route path="/fuel_reports" element={<FuelReportsPage/>} />

        <Route path="/add_vehicle" element={<AddVehiclePage/>} />

        <Route path="/edit_vehicle" element={<EditVehiclePage/>} />

        <Route path="/edit_fuel_issuing" element={<EditIssuingPage/>} />

        <Route path="/fuel" element={<FuelCalculatorPage/>} />

        <Route path="/single_vehicle" element={<SingleCarPage/>} />

        <Route path="/history" element={<SingleCarPage/>} />

        <Route path="/login" element={<LoginPage/>} />

        <Route path="/users" element={<AdminsPage/>} />
        <Route path="/create_user" element={<AddUserPage/>} />
        <Route path="/edit_user" element={<EditUserPage/>} />

    </Routes>
  );
}

export default Web;

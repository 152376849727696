import { useEffect, useRef, useState } from 'react';
import '../App.css';
import apiURL from '../values/Strings';
import ReportsSideBar from './partials/ReportsSideBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFilter } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import RotatingProgress from '../components/progress/Progress';
import { useNavigate } from 'react-router-dom';





const prepareData = (data)=>{

    let processedData = [];
    processedData.push([
        'Number Plate',
        'Vehicle User',
        'Usage Status',
        'Pick Time',
        'Return Time'
    ])
    data.forEach(obj=>{

        processedData.push([
            obj.plate_number,
            obj.name,
            obj.usage_status,
            obj.vehicle_usage_date,
            obj.vehicle_return_date
        ]);

    });

    return processedData;
}

const exportCSV = (data=[])=>{
    let csvContent = "data:text/csv;charset=utf-8,";

    data.forEach(function(rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);

    let date = new Date();
    date = date.toString();

    let fileName = `vehicle_usage_export_report_${date}.csv`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link); // Required for FF

    link.click();
}

const VehicleUsersPage =()=>{
   
    const [vehicle_user_data,setVehicleUserData] = useState([]);

    const naviator = useNavigate();

    const [isLoading,setIsLoading] = useState(false);
    const [carsLoaded,setCarsLoaded] = useState(false);
    const [activeVehicles,setActiveVehicles] = useState([]);
    const [isCustomSelect,setIsCustomSelect] = useState(false);
    const filterFormRef = useRef();

    var activeUser = window.localStorage.getItem('__fuel_user__');

    useEffect(()=>{
        if (!activeUser) {
            naviator('/login')
        }
    });


    useEffect(()=>{

        if(!carsLoaded){
            fetch(`${apiURL}/active_vehicles`,{
            }).then(response=>response.json()).then(json=>{
                setActiveVehicles(json);
               setCarsLoaded(true);
            });
        }
});

    return activeUser? (

        <div className='content m-3'>

        <div className='row'>
                    <ReportsSideBar route="/routes" activePage="vehicle_users"/>
                    <div className='col-lg-9'>
                        <div className='card m-2'>

                           <div className="card-header">
                                <h5>
                                     Vehicle Users
                                </h5>
                            </div>

                            <div className='card-body' >

                        <form ref={filterFormRef}>
                            <div className="row mb-3">
                                <div className="col-lg-3">
                                    <select name='car_id' className='form-control'>
                                        <option value="">--select vehicle--</option>

                                        {
                                            activeVehicles.map((v)=>{return <option value={v.car_id} key={v.car_id}>{v.plate_number} ({v.car_name})</option>})
                                        }
                                    </select>
                                </div>

                                <div className="col-lg-3">
                                    <select name='time_range' className='form-control' onChange={(ev)=>{
                                        if(ev.target.value==='custom'){
                                            setIsCustomSelect(true);
                                        }else{
                                            setIsCustomSelect(false);
                                        }
                                    }}>
                                        <option value="">--period--</option>
                                        <option value="today">Today</option>
                                        <option value="yesterday">Yesterday</option>
                                        <option value="a_week">1 week ago</option>
                                        <option value="custom">Custom</option>
                                    </select>
                                </div>

                                    <button type='button' className='btn btn-danger' onClick={
                                        (ev)=>{


                                            const data = new FormData(filterFormRef.current);
                                            
                                            if (data.get('car_id')==='') {
                                                window.showToast('Select a vehicle please');
                                                return;
                                            }
                                            setIsLoading(true);
                                            fetch(`${apiURL}/vehicle_users`,{
                                                method:'POST',
                                                body:data
                                            }).then(response=>response.json()).then(json=>{
                                                setVehicleUserData(json.data);
                                            }).finally(()=>{
                                                setIsLoading(false);
                                            });
                                            
                                        }
                                    }>
                                    <FontAwesomeIcon icon={faFilter}/>  Display Data
                                    </button>

                                    <Dropdown className='ml-2'>
                                        <Dropdown.Toggle variant="default"  id="dropdown-basic">
                                            Export Report
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={()=>{
                                                exportCSV(prepareData(vehicle_user_data))
                                            }} href="#">CSV</Dropdown.Item>
                                          
                                        
                                        </Dropdown.Menu>
                                    </Dropdown>
                                
                           </div>


                           <div className="col-lg-12 row mb-2"  style={{ 
                            display:isCustomSelect?'block':'none'
                            }}>

                            <div className="form-group col-lg-12">
                                <label htmlFor="">Start Date:</label>
                                <input type="date"  className='form-control' name='start_date' />
                            </div>

                            <div className="form-group col-lg-12">
                                <label htmlFor="">End Date:</label>
                            <input type="date" className='form-control' name='end_date' />
                            </div>
                           </div>

                        </form>

                        

                        <div className="row">
                            <div className="col-lg-12 overflow-auto" style={{ 
                                
                                maxHeight:'60vh'
                             }}>
                            <table className='table' >
                            <thead>
                                <tr>
                                    <th>
                                        Vehicle
                                    </th>

                                    <th>
                                        User
                                    </th>

                                    <th>
                                        Status
                                    </th>

                                    <th>
                                        Taken At
                                    </th>

                                    <th>
                                        Returned At
                                    </th>
                                </tr>
                            </thead>

                            <tbody>

                                {isLoading?<tr><td className='text-center' colSpan={4}> <RotatingProgress/> </td></tr>:''}
                                {
                                    vehicle_user_data.map(user=>{

                                        let statusClass = user.usage_status==='active'?'text-success':'text-danger';




                                        return  <tr>

                                            
                                        <td>
                                            {user.plate_number}
                                        </td>

                                        <td>
                                            {user.name}
                                        </td>

                                        <th className={statusClass}  >
                                        {user.usage_status}
                                        </th>

                                       
                                        <td>
                                        {user.vehicle_usage_date}
                                        </td>

                                        <td>
                                        {user.vehicle_return_date}
                                        </td>
                                    </tr>
                                    })
                                }
                            </tbody>
                        </table>

                            </div>
                        </div>
                            </div>

                        </div>

                    </div>
                


                    
                </div>

        </div>
       
    ):<div></div>;
}




export default VehicleUsersPage;
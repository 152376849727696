
import SideLink from '../../components/sidelink/SideLink';
import { Link } from 'react-router-dom';
import { faArrowLeft, faCar, faRoute, faUsers} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SettingsSideBar = (props)=>{

    
    return <div className="col-lg-3 sidebar">
       
       <div className='card'>
        <div className='card-header'>

            <Link to='/' className=''>

            <span className='m-r-4'>
               <FontAwesomeIcon icon={faArrowLeft}/>
             </span>   Back Home

            </Link>

        </div>
        <div className='card-body' style={{
            minHeight:'80vh'
         }}>
            
            <div className="p-2 text-dark" style={{ 
              height:'77vh',
              overflowY:'scroll'
             }}>
                   <SideLink iconComponent={
                     <span className='m-r-4'>
                      <FontAwesomeIcon icon={faRoute}/>
                    </span>  
            } route='/routes' isActive={props.activePage==='routes'} pageName='Routes' />
                   <SideLink iconComponent={ 
                   <span className='m-r-4'>
                      <FontAwesomeIcon icon={faUsers}/>
                    </span> 
                 } route='/vehicle_users' isActive={props.activePage==='vehicle_users'} pageName='Vehicle Users' />
                   <SideLink iconComponent={ 
                   <span className='m-r-4'>
                      <FontAwesomeIcon icon={faCar}/>
                    </span>  
                } route='/vehicles' isActive={props.activePage==='vehicles'} pageName='Vehicles' />
            </div>

        </div>
       </div>
    </div>
}


export default SettingsSideBar;
import { useEffect, useRef, useState } from 'react';
import '../App.css';
import apiURL from '../values/Strings';
import ReportsSideBar from './partials/ReportsSideBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus, faRefresh, faSave } from '@fortawesome/free-solid-svg-icons';
import { Toast } from 'react-bootstrap';
import LoginCheck from '../utils/LoginCheck';
import { useNavigate, useLocation } from 'react-router-dom';
import authCheck from '../utils/AuthCheck';
import RotatingProgress from '../components/progress/Progress';

const EditUserPage =(props)=>{

    const {state} = useLocation();

    const user  = state;


   const  [name,setName] = useState(user.full_name);
   const  [email,setEmail] = useState(user.email);
   const  [contact,setContact] = useState(user.contact);
   const  [user_id] = useState(state.user_id);


    const [isActive,setIsActive] = useState(false);

    const [isMessage,setIsMessage] = useState(false);

    const [isSubmitting,setIsSubmitting] = useState(false);

    const navigate = useNavigate();


    const formRef = useRef();

    async function submitUser(data) {



        if (data.get('name')==='') {
            window.showToast('Name is required');
            return;
            
        }

        if (data.get('contact')==='') {
            window.showToast('Phone contact is required');
            return;
            
        }

        if (data.get('email')==='') {
            window.showToast('Email is required');
            return;
            
        }

        setIsSubmitting(true);

        try {
          await fetch(`${apiURL}/update_user/${user_id}`, {
            method: "POST",
            body: data,
          }).then(response=>response.json()).then(result=>{
            if (result.type==='success') {
                window.showToast("User has been updated successfully",'success');
            }else{
                window.showToast(`${result.message}`,'error');
            }
            
          }).finally(()=>{
            setIsSubmitting(false)
          });
        
        } catch (error) {
            window.showToast("Something went wrong.");
        }
      }

      

      useEffect(()=>{
        if(!authCheck){
            navigate('/login')
            return;
        }
      })

    return (
        <div className='content m-3'>
        <LoginCheck/>
        <div className='row'>
                    <ReportsSideBar activePage='vehicles'/>
                    <div className='col-lg-9'>

                        <div className='card m-2'>

                            <div className="card-header">
                                <h5>
                                    Update User Information
                                </h5>
                            </div>

                            <div className='card-body'>


                        <form className="row" ref={formRef}>

                            <div className="col-lg-12 form-group">
                                <label>Full Name</label>
                                <input className='form-control' value={name} onChange={(ev)=>{
                                    setName(ev.target.value)
                                }} placeholder='Name' name='name'/>
                            </div>

                            <div className="col-lg-6 form-group">
                                <label>Phone Number</label>
                                <input className='form-control'  onChange={(ev)=>{
                                    setContact(ev.target.value)
                                }} value={contact} required placeholder='Phone Number' minLength={10} maxLength={10} name='contact'/>
                            </div>

                            <div className="col-lg-6 form-group">
                                <label>Email</label>
                                <input className='form-control'  onChange={(ev)=>{
                                    setEmail(ev.target.value)
                                }}  value={email} required placeholder='Email' name='email'/>
                            </div>


                            


                           

                            <div className="col-lg-12">
                                <input type="checkbox" name="status" value='active' checked={isActive} onChange={()=>{
                                   setIsActive(isActive?false:true)
                                }} id=""  /> Is Active
                            </div>

                        </form>

                        <hr />

                        <div className="row">
                            <div className="col-lg-12">

                            <button disabled={isSubmitting} className='btn btn-default float-left ' onClick={()=>{
                                   navigate(-1);
                                }}><FontAwesomeIcon icon={faArrowLeft}/> Back</button>
                                <button className='btn btn-default float-right ' onClick={()=>{
                                   submitUser(
                                    new FormData(formRef.current)
                                   );
                                }}>  {isSubmitting?<RotatingProgress/>: <FontAwesomeIcon icon={faSave}/>}  {isSubmitting?'Saving changes':' Save changes'} </button>
                             </div>
                        </div>

                        

                        </div>

                        </div>

                    </div>
                
                </div>

        </div>
       
    );
}

export default EditUserPage;
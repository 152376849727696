import '../App.css';
import VehiclesSideBar from './partials/VehiclesSideBar';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import React, { useRef, useState, useEffect } from 'react';
import  '../styles/Map.css';
import apiURL from '../values/Strings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBicycle, faCar, faClose } from '@fortawesome/free-solid-svg-icons';
import ReactDOM  from 'react-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginCheck from '../utils/LoginCheck';
import authCheck from '../utils/AuthCheck';
import LoginPage from './auth/LoginPage';



mapboxgl.accessToken = 'pk.eyJ1Ijoia2lzYWt5ZW5qIiwiYSI6ImNsOHd2OXZkbDAwZ2Qzcmx5MHdmcWU0ZTAifQ.5HXwrE0hrV8ASva9zlAAhQ';

var mapMarkers = [];


window.dataHasBeenLoaded = false;
window.interHasBeenSet = false;

window.loadedTrackingData =[];


const MarkerPopup = (props)=>{
    
    return <div 

    className='marker-popup mt-4 mb-4' style={
        {
            zIndex:'233333',
            minHeight:'90%',
        
        }
    }>

        <div>
            <button onClick={()=>{
               const popupContainer =  window.document.querySelector('.popup-container');
               popupContainer.style.display='none';

            }} className='btn-default m-1 float-right' style={
                {
                    borderRadius:'50%',

                    border:'none',
                    outline:'none',
                    backgroundColor:'transparent'
                }
            }><FontAwesomeIcon icon={faClose}/></button>
        </div>
        <h6 className='text-bold text-center'>Tracking Details</h6>
        <h5 className='text-center'>{props.data.plate_number}</h5>

        <hr/>
        

        <div style={{ 
            height:'100%',
            overflow:'auto',
            zIndex:'15'
         }}>
            <p>Licence plate: <span className='text-grey'>{props.data.plate_number}</span></p>
            <p>Vehicle Name: <span className='text-grey'>{props.data.car_name}</span></p>
            <p>Longitude: <span className='text-grey'>{props.data.user_lon}</span></p>
            <p>Latitude: <span className='text-grey'>{props.data.user_lat}</span></p>
            <p>Address: <span  className='text-primary' style={{ 
                                                cursor:'pointer'
                                             }} to='#' onClick={(ev)=>{

                                                const lon = props.data.user_lon;
                                                const lat = props.data.user_lat;

                                                fetch(`${apiURL}/get_address?latitude=${lat}&longitude=${lon}`,{
                                                }).then(response=>response.json()).then(json=>{
                                                   const address = (json.address);

                                                   if(address){
                                                    ev.target.classList.remove('text-primary');
                                                     ev.target.innerHTML = address;
                                                     
                                                   }
    
                                                });

                                            }}>Show Address</span></p>
            <p>Speed: <span className='text-grey'>0m/s</span></p>

            <h6 className='text-bold'>Vehicle User Details</h6>
            <hr/>

            <p>Name: <span className='text-grey'>{props.data.name}</span></p>
            <p>Contact: <span className='text-grey'>{props.data.contact}</span></p>
            <p>Department: <span className='text-grey'>{props.data.department_name}</span></p>
            <p>Designation: <span className='text-grey'>{props.data.designation_name}</span></p>

        </div>

        
    </div>
}






const IndexPage =()=>{

    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(29.98112349);
    const [lat, setLat] = useState(0.36341234);
    const [zoom, setZoom] = useState(6);

    const [isMounted,setIsMounted] = useState(false);

    const location = useLocation();

    const popupRef = useRef();

    const navigator = useNavigate();

    

        useEffect(() => {
            var activeUser = window.localStorage.getItem('__fuel_user__');

            if (activeUser) {
                if (map.current) return; // initialize map only once
                map.current = new mapboxgl.Map({
                  container: mapContainer.current,
                  style: 'mapbox://styles/mapbox/streets-v12',
                  center: [lng, lat],
                  zoom: zoom
                });
        
                if(!isMounted){
                    map.current.addControl(new mapboxgl.NavigationControl(), "top-right");
                    setIsMounted(true);
                }
        
             
        
            }else{
                navigator('/login');
            }

        },[location]);
        
           
        

        useEffect(()=>{
            var activeUser = window.localStorage.getItem('__fuel_user__');
            if (!activeUser) {
                navigator('/login')
            }
        })
    
   


      useEffect(() => {
        if (!map.current) return; 

            // Add navigation control (the +/- zoom buttons)
            map.current.on('move', () => {
                setLng(map.current.getCenter().lng.toFixed(4));
                setLat(map.current.getCenter().lat.toFixed(4));
                setZoom(map.current.getZoom().toFixed(2));
            });

            //loadData();

            console.log(map.current);
            if (window.interHasBeenSet===false) {
                setInterval(function(){
                    loadData();
                },6500);

                window.interHasBeenSet = true;

            }
            
                          
    });


const Marker = (props) => {
    const _onClick = (ev) => {
     setPopupData(
        props.data
     )

     popupRef.current.style.display='block';
    };
    return (
    <div className='text-center' style={{ 
        display:'grid',
        placeItems:'center',
        
     }}>
    <p className='text-center text-bold text-danger p-1' style={{ 
        boxShadow:'0px 0px 3px lightgrey',
        borderRadius:'3px',
        backgroundColor:'white'
        
     }}>{props.data.plate_number}</p>
      <button style={{ 
        boxShadow:'0px 0px 3px lightgrey',
     }} onClick={_onClick} className="custom-marker">
        <span className='badge badge-success badge-sm'> </span>
        <span className=''> <FontAwesomeIcon icon={props.data.car_type==='bike'?faBicycle:faCar} /> </span>
      </button>
     
      </div>
    );
};

      const loadData = ()=>{
        fetch(`${apiURL}/home_tracking`)
                .then(response=>response.json()).then(json=>{
                   window.loadedTrackingData = json;
                   populateMapWithData(window.loadedTrackingData);
                })

      }




      const  populateMapWithData = (data=[])=>{
        mapMarkers.forEach(marker=>{
            marker.remove();
      });
        data.forEach(item=>{
            // Create a React ref
            const ref = React.createRef();

            // Create a new DOM node and save it to the React ref
            ref.current = document.createElement("div");

            //Render a Marker Component on our new DOM node
            ReactDOM.render(
                <Marker data={item} type=""  />,
                ref.current
            );

          var marker =   new mapboxgl.Marker(ref.current).setLngLat([item.user_lon,item.user_lat])
            .addTo(map.current);

           mapMarkers.push(marker);
       });
      }


const [popupData,setPopupData] = useState({});


var activeUser = window.localStorage.getItem('__fuel_user__');
    if (activeUser) {
        return (

            <div className='content'>
            
            <div ref={popupRef} className='popup-container' style={{
                display:'none',
                zIndex:'2333333',
                backgroundColor:'green'
            }}>
            <MarkerPopup data={popupData}/>
            </div>
             
            <div className='row'>
                        <VehiclesSideBar/>
                        <div className='col-lg-12' style={{ 
                            position:'absolute',
                            height:'100vh',
                            
                         }}>
                            <div className='map-container' ref={mapContainer} style={{ 
                                height:'100',
                                zIndex:3,
                                display:''
                                }}></div>
    
                        </div>
                    </div>
    
            </div>
           
        );
        
    }

    return <div></div>;

   
    
}

export default IndexPage;
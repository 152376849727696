import '../App.css';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import React, { useRef, useState, useEffect } from 'react';
import  '../styles/Map.css';
import apiURL from '../values/Strings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBicycle, faCar } from '@fortawesome/free-solid-svg-icons';
import ReactDOM  from 'react-dom/client';
import { useLocation } from 'react-router-dom';
import LoginPage from './auth/LoginPage';
import SingleCarSideBar from './partials/SingleCarSideBar';


mapboxgl.accessToken = 'pk.eyJ1Ijoia2lzYWt5ZW5qIiwiYSI6ImNsOHd2OXZkbDAwZ2Qzcmx5MHdmcWU0ZTAifQ.5HXwrE0hrV8ASva9zlAAhQ';

var mapMarkers = [];


var isShown = false;
const MarkerPopup = (props)=>{
    isShown = true;
    return <div 
    className='marker-popup' style={
        {
            display:(props.isPopupShown===true&&isShown===true)?'block':'none'
        }
    }>
        <h4 className='text-center'>{props.data.plate_number}</h4>
        <hr />

        <div style={{ 
            height:'70%',
            overflow:'auto',
            zIndex:'15'
         }}>

            <h6 className='text-bold'>Tracking Details</h6>
            <hr/>
            <p>Licence plate: <span className='text-grey'>{props.data.plate_number}</span></p>
            <p>Vehicle Name: <span className='text-grey'>{props.data.car_name}</span></p>
            <p>Longitude: <span className='text-grey'>{props.data.user_lon}</span></p>
            <p>Latitude: <span className='text-grey'>{props.data.user_lat}</span></p>
            <p>Address: <span className='text-info'>Show address</span></p>
            <p>Speed: <span className='text-grey'>0m/s</span></p>

            <h6 className='text-bold'>Vehicle User Details</h6>
            <hr/>

            <p>Name: <span className='text-grey'>{props.data.name}</span></p>
            <p>Contact: <span className='text-grey'>{props.data.contact}</span></p>
            <p>Department: <span className='text-grey'>{props.data.department_name}</span></p>
            <p>Designation: <span className='text-grey'>{props.data.designation_name}</span></p>

        </div>

        <br/>

        <button onClick={
          ()=>{
           isShown = false;
          }
        } className='btn btn-info col-lg-12'>OKAY</button>
    </div>
}

const SingleCarPage =()=>{

    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(29.98112349);
    const [lat, setLat] = useState(0.36341234);
    const [zoom, setZoom] = useState(6);

    const [isMounted,setIsMounted] = useState(false);

    const location = useLocation();

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/mapbox/streets-v12',
          center: [lng, lat],
          zoom: zoom
        });

        if(!isMounted){
            map.current.addControl(new mapboxgl.NavigationControl(), "top-right");
            setIsMounted(true);
        }

      },[location]);


      useEffect(() => {
        if (!map.current) return; 

            // Add navigation control (the +/- zoom buttons)
            map.current.on('move', () => {
                setLng(map.current.getCenter().lng.toFixed(4));
                setLat(map.current.getCenter().lat.toFixed(4));
                setZoom(map.current.getZoom().toFixed(2));
            });

        

            map.current.on('load',()=>{
                    loadData();
            },[location])
                          
    });


    useEffect(()=>{
        loadData()
    })




const Marker = (props) => {
    const _onClick = (ev) => {

    showPopup({});
   
     setPopupData(
        props.data
     )
    };
    return (

    <div className='text-center' style={{ 
        display:'grid',
        placeItems:'center'
     }}>

   <LoginPage/>
    <p className='text-center text-bold text-danger p-1' style={{ 
        boxShadow:'0px 0px 3px lightgrey',
        borderRadius:'3px',
        backgroundColor:'white'
        
     }}>{props.data.plate_number}</p>
      <button style={{ 
        boxShadow:'0px 0px 3px lightgrey',
     }} onClick={_onClick} className="custom-marker">
        <span className='badge badge-success badge-sm'> </span>
        <span className=''> <FontAwesomeIcon icon={props.data.car_type==='bike'?faBicycle:faCar} /> </span>
      </button>
     
      </div>
    );
};



      const loadData = ()=>{
        fetch(`${apiURL}/vehicle_routes`)
                .then(response=>response.json()).then(json=>{
                    mapMarkers.forEach(marker=>{
                          marker.remove();
                    });

                    console.log(json);
                   json.forEach(item=>{
                        // Create a React ref
                        const ref = React.createRef();

                        // Create a new DOM node and save it to the React ref
                        ref.current = document.createElement("div");

                        ref.current.classList.add('marker-dot')

                        // Render a Marker Component on our new DOM node
                        ReactDOM.createRoot(
                            // <Marker data={item} type="" />,
                            ref.current
                        );

                      var marker =   new mapboxgl.Marker(ref.current).setLngLat([item.user_lon,item.user_lat])
                        .addTo(map.current);

                       mapMarkers.push(marker);


                   });
                }).catch(err=>{
                    console.error(err);
                })

      }


const showPopup =(data)=>{
    setIsPopupShown(true);
}

const [popupData,setPopupData] = useState({});
const [isPopupShown,setIsPopupShown] = useState(false);

    return (

        <div className='content'>
         <MarkerPopup  isPopupShown={isPopupShown} data={popupData}/>
        <div className='row'>
                    <SingleCarSideBar   vehicles={[]}  />
                    <div className='col-lg-12' style={{ 
                        position:'absolute',
                        margin:'0px',
                        height:'100vh'
                     }}>
                        <div className='map-container' ref={mapContainer} style={{ 
                            height:'100%',
                          
                            }}></div>
                        

                    </div>
                </div>

        </div>
       
    );
}


export default SingleCarPage;
import '../App.css';
import SettingsSideBar from './partials/SettingsSideBar';

const SettingsPage =()=>{
    return (

        <div className='content m-3'>
        <div className='row'>

                    <SettingsSideBar/>
                    
                    <div className='col-lg-9'>

                        <div className='card'>

                            <div className='card-body'>


                        <table className='table' >
                            <thead>
                                <tr>
                                    <th>
                                        Vehicle
                                    </th>

                                    <th>
                                        User
                                    </th>
                                    <th>
                                        Longitude
                                    </th>

                                    <th>
                                        Latitude
                                    </th>

                                    <th>
                                        Address
                                    </th>
                                </tr>
                            </thead>
                        </table>

                            </div>

                        </div>

                    </div>
                


                    
                </div>

        </div>
       
    );
}


export default SettingsPage;
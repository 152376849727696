import { faCar, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate} from "react-router-dom";
import apiURL from "../../values/Strings";
import { useEffect, useRef, useState } from "react";
import authCheck from "../../utils/AuthCheck";
import RotatingProgress from '../../components/progress/Progress';




const LoginPage = ()=>{

    const [loginSuccess,setLoginSuccess] = useState(false);

    const formRef = useRef();

    const [isSubmitting,setIsSubmitting] = useState(false);

    const navigator = useNavigate();


    useEffect(()=>{
        var activeUser = window.localStorage.getItem('__fuel_user__');

        if (activeUser) {
            navigator('/home')
        }
    })


    const checkLogin = ()=>{

        const formData = new FormData(formRef.current);

        const phone = formData.get('user_name');
        const pass = formData.get('password');

        if (phone===''||pass==='') {
            window.showToast('All fields are required.')
            return;
        }

        setIsSubmitting(true);

        fetch(`${apiURL}/web_login`,{
            method: "POST",
            body:new FormData(formRef.current),
        })
        .then(response=>response.json())
        .then(json=>{
            if (json.user) {
                setLoginSuccess(true)
                window.localStorage.setItem('__fuel_user__',JSON.stringify(json.user));

                console.log("logging in");
                navigator('/home')
            }else{
                window.showToast('Login attempt failed');
            }
           
        }).catch(err=>{
            window.showToast('Something went wrong');
        }).finally(()=>{
            setIsSubmitting(false);
        })
    }


    const activeUser = window.localStorage.getItem('__fuel_user__');
    if (activeUser) {
        return <div></div>
    }

    return <div className="content">
        <div className="row d-flex justify-content-center align-items-center" style={{ 
            display:'flex',
            justifyContent:'center',
            alignContent:'center',
            height:'100vh',
            backgroundColor:'#f0f0f0'
         }}>

        <div className="col-lg-3">

            <div className="text-center  w-100">
            
                <FontAwesomeIcon icon={faCar} style={{ 
                    height:'50px',
                    width:'50px'
                 }}/>

           
            </div>
            <h5 className="text-center text-bold text-danger">SEAGATE TRIPS PORTAL</h5>

            <br />

            <form ref={formRef} className="card"  style={{ 
                borderRadius:'0px'
             }}> 

            <div className="card-body">
                <div className="form-group">
                    <label htmlFor="">Phone</label>
                    <input className="form-control" name="user_name" placeholder="Phone number"/>
                </div>

                <div className="form-group">
                    <label htmlFor="">Password</label>
                    <input type="password" className="form-control" name="password" placeholder="Password"/>
                </div>
                <button onClick={checkLogin} disabled={isSubmitting} type="button" className="btn btn-danger btn-flat form-control"> <span>  {isSubmitting?<RotatingProgress color='white' />:<FontAwesomeIcon fade={true} icon={faUnlock}/> } </span>   {isSubmitting?'Checking..':'Login'} </button>
                 <hr />

                <div className="text-center">
                   <Link to="#" className="text-bold text-danger text-center">Forgot password?</Link>
                </div>

                <div className="">
                    <br />
                    <small className="text-bold text-grey">
                     <span className="text-danger">Note:</span> Contact  Administrator for Accounts.
                    </small>
                </div>

            </div>

            </form>

        </div>

        <div className="col-lg-12" style={{ 
            position:'absolute',
            left:'0',
            bottom:'0',
            right:'0'
         }}>
            <p className="footer" style={{ 
                textAlign:'center'
             }}>
                <small className="text-right">
                    &copy; Copyright {new Date().getFullYear()} All rights reserverd
                </small>
            </p>
        </div>

        </div>
    </div>
}

export default LoginPage;
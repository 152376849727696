import { useEffect, useRef, useState } from 'react';
import '../App.css';
import apiURL from '../values/Strings';
import ReportsSideBar from './partials/ReportsSideBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faRefresh, faSave } from '@fortawesome/free-solid-svg-icons';
import { Toast } from 'react-bootstrap';
import LoginCheck from '../utils/LoginCheck';
import { useNavigate } from 'react-router-dom';
import authCheck from '../utils/AuthCheck';

const AddUserPage =()=>{

    const [isActive,setIsActive] = useState(false);

   


    const formRef = useRef();

    async function submitUser(data) {


        if (data.get('name')==='') {
            window.showToast('Name is required');
            return;
            
        }

        if (data.get('contact')==='') {
            window.showToast('Phone contact is required');
            return;
            
        }

        if (data.get('email')==='') {
            window.showToast('Email is required');
            return;
            
        }



        try {
          await fetch(`${apiURL}/create_user`, {
            method: "POST",
            body: data,
          }).then(response=>response.json()).then(result=>{
            formRef.current.reset();
            window.showToast('User account has been created successfully.','success')
            
          });
        
        } catch (error) {
          console.error("Error:", error);
        }
      }

      const navigate = useNavigate();

      useEffect(()=>{
        if(!authCheck){
            navigate('/login')
            return;
        }
      })

    return (
        <div className='content m-3'>
        <LoginCheck/>
        <div className='row'>
                    <ReportsSideBar activePage='vehicles'/>
                    <div className='col-lg-9'>

                        <div className='card m-2'>

                            <div className="card-header">
                                <h5>
                                    System User Registration
                                </h5>
                            </div>

                            <div className='card-body'>

                        <form className="row" ref={formRef}>

                            <div className="col-lg-12 form-group">
                                <label>Full Name</label>
                                <input className='form-control' placeholder='Name' name='name'/>
                            </div>

                            <div className="col-lg-6 form-group">
                                <label>Phone Number</label>
                                <input className='form-control' required placeholder='Phone Number' minLength={10} maxLength={10} name='contact'/>
                            </div>

                            <div className="col-lg-6 form-group">
                                <label>Email</label>
                                <input className='form-control' required placeholder='Email' name='email'/>
                            </div>


                            <div className="col-lg-12 form-group">
                                <label>Password</label>
                                <input className='form-control' required placeholder='Password' value={'seagate@admin'} name='password'/>
                            </div>

                            <div className="col-lg-12">
                                <input type="checkbox" name="status" value='active' checked={isActive} onChange={()=>{
                                   setIsActive(isActive?false:true)
                                }} id=""  /> Is Active
                            </div>

                        </form>

                        <div className="row">
                            <div className="col-lg-12">
                                <button className='btn btn-default float-right ' onClick={()=>{
                                   submitUser(
                                    new FormData(formRef.current)
                                   );
                                }}><FontAwesomeIcon icon={faSave}/> Create User</button>
                             </div>
                        </div>

                        </div>

                        </div>

                    </div>
                
                </div>

        </div>
       
    );
}


export default AddUserPage;

import { Link, useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const turf = window.turf;

const CalculatorSideBar = (props)=>{

   const [fuelBill,setFuelBill]  = useState(0.0);
   const [tripDistance,setTripDistance] = useState(0.0);
   const [tripFuel,setTripFuel] = useState(0.0);
   const [fuelPrice,setFuelPrice] = useState(0.0);
   const [forTwice,setForTwice] = useState(false);

   const ccRef = useRef();
   const priceRef = useRef();

  

   const navigator = useNavigate();

    function computeFuel(cc,price){
         var to = [props.destLngLat.lng, props.destLngLat.lat] //lng, lat
         var from = [props.srcLngLat.lng, props.srcLngLat.lat] //lng, lat

         var options = {
            units: 'kilometers'
          };
         var distance = turf.distance(to,from,options);
         var fuel = 8.38*cc*distance/(130000);

         if (forTwice) {
          fuel = fuel*2;
         }

         setTripFuel(fuel);

         setTripDistance(distance);

         setFuelBill(
            fuel*price)
    
    }

    return <div className="col-lg-3" style={{ 
      position:'static',
      left:'12px',
      top:'12px',
      zIndex:'18',
     }}>
       

       <div className='card m-3 bd-info'>
        <div className='card-body' >
           <div className="row">
            <div className="col-lg-12">
               <Link  href="#"  onClick={()=>{
                   navigator(-1);
               }}>
                 <h5> <FontAwesomeIcon icon={faArrowLeft} className='mr-2'/> Calculate fuel</h5>
               </Link>

            </div>
            </div>
            <div className="p-1" style={{ 
              height:'min-content',
              overflowY:'scroll'
             }}>

                <input type="number" 
                onChange={(ev)=>{

                  if (ev.target.value<0) {
                    setFuelPrice(0.0)
                  }

                }}
                onInput={ (ev)=>{
                    setFuelPrice(ev.target.value);
                    if (props.destLngLat.lat) {
                        computeFuel(
                            ccRef.current.value,
                            ev.target.value
                        )
                    }

                }}

                ref={priceRef}   required className='form-control mt-1 mb-1' placeholder='Fuel Price' />

              <select ref={ccRef} className='form-control'>
                {
                  props.vehicles.map(vehicle=><option key={vehicle.car_id} value={vehicle.car_engine_capacity}>{vehicle.plate_number}</option>)
                 }
                </select>

               

                <div className='p-2'>
                <div className='row justify-content-between'>
                <span>Distance(KM):</span> <span>{ props.destLngLat.lat?tripDistance.toFixed([2]):0.0}</span>
                </div>

                <div className='row justify-content-between'>
                  <span>Fuel(Ltrs):</span> <span>{props.destLngLat.lat?tripFuel.toFixed([2]):0.0}</span>
                </div>
                </div>
          


           

           
{/* 
            <p>
               From: {`${props.srcLngLat.lat??0.0},${props.srcLngLat.lng??0.0}`}
            </p>

            <p>
                To:{`${props.destLngLat.lat??0.0},${props.destLngLat.lng??0.0}`}
            </p> */}


             <br />
           <p>
            <input type="checkbox" onChange={(ev)=>{
                setForTwice(ev.target.checked);

                
              }} name="" value={forTwice} /> To and fro
           </p>
            </div>



<hr />

            <div>
               <h4 className='text-dark text-bold text-center'>

             
               UGX
             
                  {props.destLngLat.lat?fuelBill.toFixed([2]):0.0}
               </h4>


               
               <p className='text-center'>

               <small className='text-success text-center'>
                Estimated fuel bill.
               </small>

               </p>


               <br/>
                           
                <button
                style={
                    {
                        pointerEvents:props.destLngLat.lat!=null?'all':'none'
                    }
                }
                 onClick={()=>{
                    computeFuel(
                        ccRef.current.value,
                        priceRef.current.value
                    );
                 }} className='btn  btn-danger form-control'>Calculate</button>

        </div>





        </div>

       
       </div>

       </div>
    
}


export default CalculatorSideBar;
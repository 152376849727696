
import SideLink from '../../components/sidelink/SideLink';
import { Link, useNavigate } from 'react-router-dom';
import { faArrowLeft, faArrowLeftLong, faCar, faGasPump, faRoute, faSignOutAlt, faUsers} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ReportsSideBar = (props)=>{


   const navigate = useNavigate();

    
    return <div className="col-lg-3">
       

       <div className='card mt-2 ml-1 '>
        <div className='card-header'>

            <Link to='/home' className=''>

            <span className='m-r-4'>
               <FontAwesomeIcon icon={faArrowLeftLong}/>
             </span>   Back Home

            </Link>

        </div>
        <div className='card-body p-2' style={{
            minHeight:'80vh'
         }}>
            
            <div className="p-1 text-dark" style={{ 
              height:'77vh',
              overflowY:'scroll'
             }}>
                   <SideLink iconComponent={
                     <span className='m-r-4'>
                      <FontAwesomeIcon icon={faRoute}/>
                    </span>  
            } route='/routes' isActive={props.activePage==='routes'} pageName='History' />

  

                   <SideLink iconComponent={ 
                   <span className='m-r-4'>
                      <FontAwesomeIcon icon={faUsers}/>
                    </span> 
                 } route='/vehicle_users' isActive={props.activePage==='vehicle_users'} pageName='Vehicle Users' />
                   <SideLink iconComponent={ 
                   <span className='m-r-4'>
                      <FontAwesomeIcon icon={faCar}/>
                    </span>  
                } route='/vehicles' isActive={props.activePage==='vehicles'} pageName='Vehicles' />



                <SideLink iconComponent={ 
                   <span className='m-r-4'>
                      <FontAwesomeIcon icon={faUsers}/>
                    </span>  
                } route='/users' isActive={props.activePage==='users'} pageName='Users' />



            <SideLink iconComponent={ 
                   <span className='m-r-4'>
                      <FontAwesomeIcon icon={faGasPump}/>
                    </span>  
                } route='/fuel_reports' isActive={props.activePage==='fuel_reports'} pageName='Fuel' />

            </div>

        </div>


       <div className='p-2'>

       <button onClick={(ev)=>{
            window.localStorage.removeItem('__fuel_user__');
            navigate('/login');
         }} className='btn btn-danger form-control'><FontAwesomeIcon icon={faSignOutAlt} /> Signout</button>

       </div>


        
       </div>
    </div>
}


export default ReportsSideBar;
import { useEffect, useRef, useState } from 'react';
import '../App.css';
import apiURL from '../values/Strings';
import ReportsSideBar from './partials/ReportsSideBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSave } from '@fortawesome/free-solid-svg-icons';
import LoginCheck from '../utils/LoginCheck';
import { useNavigate } from 'react-router-dom';
import authCheck from '../utils/AuthCheck';

const AddVehiclePage =()=>{

    const [isActive,setIsActive] = useState(false);

    const [isMessage,setIsMessage] = useState(false);


    const formRef = useRef();

    async function submitVehicle(data) {

        try {
          await fetch(`${apiURL}/add_vehicle`, {
            method: "POST",
            body: data,
          }).then(response=>response.json()).then(result=>{
            console.log("Success:", result);
            console.log(formRef.current.reset());
            setIsMessage(true);
          });
        
        } catch (error) {
          console.error("Error:", error);
        }
      }

      var activeUser = window.localStorage.getItem('__fuel_user__');


      const navigate = useNavigate();

      useEffect(()=>{
        if(!activeUser){
            navigate('/login')
        }
      })

    return activeUser? (
        <div className='content m-3'>
        <LoginCheck/>
        <div className='row'>
                    <ReportsSideBar activePage='vehicles'/>
                    <div className='col-lg-9'>

                        <div className='card m-2'>

                            <div className="card-header">
                                <h5>
                                    Vehicle Registration
                                </h5>
                            </div>

                            <div className='card-body'>


                        <p style={{ 
                             display:isMessage?'block':'none'
                         }} className='alert alert-success row justify-content-between' >
                            <span>Vehicle record created successfully </span> <button className='' 
                            
                            onClick={()=>{
                                setIsMessage(false)
                            }}
                            style={{ 
                                backgroundColor:'transparent',
                                outline:'none',
                                border:'none',
                                color:'white',
                             }}>&times;</button>
                        </p>



                        <form className="row" ref={formRef}>



                            <div className="col-lg-6 form-group">
                                <label>Vehicle Name</label>
                                <input className='form-control' placeholder='Vehicle Name' name='vehicle_name'/>
                            </div>


                            <div className="col-lg-6 form-group">
                                <label>Number Plate</label>
                                <input className='form-control' required placeholder='Number Plate' name='number_plate'/>
                            </div>


                            <div className="col-lg-4 form-group">
                                <label>Vehicle Type</label>
                                <select className='form-control' required name='vehicle_type'>
                                    <option value="car">
                                        Car
                                    </option>

                                    <option value="bike">
                                        Motor Bike
                                    </option>
                                </select>
                            </div>

                            <div className="col-lg-4 form-group">
                                <label>Engine Size(CC)</label>
                                <input className='form-control'required placeholder='Engine Size(CC)' name='engine_size'/>
                            </div>

                            <div className="form-group col-lg-4">
                                <label>Fuel Type</label>
                                <select required className='form-control' placeholder='' name='fuel_type'>
                                    <option value="petrol">
                                        Petrol
                                    </option>

                                    <option value="diesel">
                                        Diesel
                                    </option>

                                </select>
                            </div>


                            <div className="form-group col-lg-12">
                                <label>Mechanical Condition</label>
                                <select className='form-control' placeholder='' name='condition'>
                                    <option value="good">
                                        Good
                                    </option>

                                    <option value="moderate">
                                        Moderate
                                    </option>

                                    <option value="poor">
                                        Poor
                                    </option>
                                </select>
                            </div>

                            <div className="col-lg-12">
                                <input type="checkbox" name="status" value='active' checked={isActive} onChange={()=>{
                                   setIsActive(isActive?false:true)
                                }} id=""  /> Is Active
                            </div>

                        </form>

                        <div className="row">
                            <div className="col-lg-12">
                                <button className='btn btn-default float-right ' onClick={()=>{
                                   submitVehicle(
                                    new FormData(formRef.current)
                                   );
                                }}><FontAwesomeIcon icon={faSave}/> Create</button>
                             </div>
                        </div>

                        </div>

                        </div>

                    </div>
                
                </div>

        </div>
       
    ):<div></div>;
}


export default AddVehiclePage;
import { Link, useNavigate } from 'react-router-dom';
import { useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';


const SingleCarSideBar = (props)=>{

   const ccRef = useRef();

   const navigator = useNavigate();

    function fetchInfo(cc,price){
        // var to = [props.destLngLat.lng, props.destLngLat.lat] //lng, lat
        //  var from = [props.srcLngLat.lng, props.srcLngLat.lat] //lng, lat

        //  var options = {
        //     units: 'kilometers'
        //   };
        //  var distance = turf.distance(to,from,options);
        //  var fuel = 8.38*cc*distance/(130000);

    }

    return <div className="col-lg-3 sidebar" style={{ 
      position:'static',
      marginLeft:'12px',
      marginTop:'12px',
      zIndex:'18'
      
     }}>
       

     <div style={{
            minHeight:'100vh'
         }}>
       <div className='card btn-flat'>

        <div className="card-header">
        <Link  href="#"  onClick={()=>{
                   navigator(-1);
               }}>
                <br />
                 <h5><FontAwesomeIcon icon={faArrowLeftLong} className='mr-2'/> Vehicle Routes</h5>
               </Link>
        </div>
        <div className='card-body' >
           <div className="row">
            <div className="col-lg-12">

             

            </div>
            </div>
          
            <div className="p-1" style={{ 
              minHeight:'20vh',
              overflowY:'scroll'
             }}>

              <select ref={ccRef} className='form-control custom-select'>

                <option value="">Select a vehicle</option>
                {
                  props.vehicles.map(vehicle=><option key={vehicle.car_id} value={vehicle.car_engine_capacity}>{vehicle.plate_number}</option>)
                 }
             </select>

             <select  className='form-control mt-2 custom-select'>
                 <option value="today">Today</option>
                 <option value="Yesterday">Yesterday</option>
            </select>



            </div>

            <hr />

            <p className='text-bold'>Map Key</p>

            <div style={{
                display:'flex',
                alignContent:'center'
             }}>
                <i className='mt-2' style={{
                    borderRadius:'50%',
                height:'11px',
                width:'11px',
                backgroundColor:'red'
             }}></i> 

             <div className='ml-2'>
            <small> Vehicle Position</small>
             </div>
            
             </div>

        </div>

        <div className="card-footer">
                <button
                 onClick={()=>{
                   fetchInfo()
                 }} className='btn btn-danger  form-control'>Display</button>

        </div>
       </div>

       </div>
    </div>
}

export default SingleCarSideBar;
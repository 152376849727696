import { faArrowCircleRight, faArrowRight, faArrowRightLong, faArrowRotateRight, faArrowUpRightDots, faListDots } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const  SideLink = (props)=>{

    const linkStyle = { 
        color:'black'
   };

     console.log(props.isActive);

    return  <div style={{ 
      backgroundColor:props.isActive===true?'red !important':'',
      color:props.isActive===true?'white !important':'black'
     }}>


    <Link  to={props.route}    className="col-lg-12 nav-link car-side  mt-2 mb-2 " style={{
            cursor:'pointer',
            
            
            minHeight:'47px',
            borderRadius:'5px',
            justifyContent:'space-between',
            backgroundColor:'transparent !important',
             boxShadow:'0px 0px 2px lightgrey',
            
        }}>

          <div>

          <span className='mr-3' style={{ 
              
              color:props.isActive===true?'red':'black'
              }}>
              {props.iconComponent}
             </span>  
            <span className='' style={{ 
               
               color:props.isActive===true?'red':'black'
             }}>
              {props.pageName}
            </span>

          </div>
       
            

            <span style={{ 
               color:props.isActive===true?'red':'black'
             }}>
              <FontAwesomeIcon icon={faArrowRightLong}/>
            </span>
       
            
    </Link>

    </div>

}

export default SideLink;
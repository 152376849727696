import { useEffect, useRef, useState } from 'react';
import '../App.css';
import apiURL from '../values/Strings';
import ReportsSideBar from './partials/ReportsSideBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faSave } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import RotatingProgress from '../components/progress/Progress';

const EditVehiclePage =()=>{

    const {state} = useLocation();

    const [isActive,setIsActive] = useState(true);
    const [isSaving,setIsSaving] = useState(false);

    const [vName,setVName] = useState(state.car_name);
    const [vPlate,setVPlate] = useState(state.plate_number);
    const [vCC,setVCC ]= useState(state.car_engine_capacity);

    const formRef = useRef();
    async function submitVehicle(data) {

        try {

        setIsSaving(true);
          await fetch(`${apiURL}/update_vehicle/${state.car_id}`, {
            method: "POST",
            body: data,
          }).then(response=>response.json()).then(result=>{
            setIsSaving(false);
            window.showToast('Changes saved successfully','success');

            console.log(result);
          });
        
        } catch (error) {
          console.error("Error:", error);
          window.showToast('Something went wrong','error');
          setIsSaving(false);
        }
      }

    var activeUser = window.localStorage.getItem('__fuel_user__');


    const navigator = useNavigate();
    useEffect(()=>{
        if (!activeUser) {
            navigator('/login');
        }
    })

   
        return  activeUser?(
            <div className='content m-3'>
            <div className='row'>
                        <ReportsSideBar activePage='vehicles'/>
                        <div className='col-lg-9'>
    
                            <div className='card m-2'>

                                <div className="card-header">
                                    <h5>Edit Vehicle Details</h5>
                                </div>
    
                                <div className='card-body'>
    
                            

                            <br/>

                            <form className="row" ref={formRef}>
    
    
    
                                <div className="col-lg-12 form-group">
                                    <label>Vehicle Name</label>
                                    <input  className='form-control' onChange={(ev)=>{
                                        setVName(ev.target.value)
                                    }} value={vName} placeholder='Vehicle Name' name='car_name'/>
                                </div>
    
    
                                <div className="col-lg-12 form-group">
                                    <label>Number Plate</label>
                                    <input className='form-control' onChange={(ev)=>{
                                        setVPlate(ev.target.value)
                                    }}  value={vPlate} required placeholder='Number Plate' name='plate_number'/>
                                </div>


                                    
    
                                <div className="col-lg-4 form-group">
                                    <label>Vehicle Type</label>
                                    <select className='form-control' required name='car_type'>

                                        {
                                            state.car_type==='bike'?<option value="bike">
                                            Motor Bike
                                            </option>:<option value="car">
                                            Car
                                            </option>
                                        }

                                         {
                                            state.car_type==='car'?<option value="bike">
                                            Motor Bike
                                            </option>:<option value="car">
                                            Car
                                            </option>
                                        }


                                    </select>
                                </div>
    
                                <div className="col-lg-4 form-group">
                                    <label>Engine Size(CC)</label>
                                    <input value={vCC} onChange={(ev)=>{
                                        setVCC(ev.target.value)
                                    }}  className='form-control'required placeholder='Engine Size(CC)' name='engine_size'/>
                                </div>
    
                                <div className="form-group col-lg-4">
                                    <label>Fuel Type</label>
                                    <select required className='form-control' placeholder='' name='fuel_type'>
                                        <option value="petrol">
                                            Petrol
                                        </option>
    
                                        <option value="diesel">
                                            Diesel
                                        </option>
    
                                    </select>
                                </div>
    
    
                                <div className="form-group col-lg-12">
                                    <label>Mechanical Condition</label>
                                    <select className='form-control' placeholder='' name='car_condition'>
                                        <option value="good">
                                            Good
                                        </option>
    
                                        <option value="moderate">
                                            Moderate
                                        </option>
    
                                        <option value="poor">
                                            Poor
                                        </option>
                                    </select>
                                </div>
    
                                <div className="col-lg-12">
                                    <input type="checkbox" name="status"   value='active' checked={isActive} onChange={()=>{
                                       setIsActive(isActive?false:true)
                                    }} id=""  /> Is Active
                                </div>
    
                            </form>

                            <div className="row">
                                <div className="col-lg-12">
                                    <button   disabled={isSaving} className='btn btn-default float-right ' onClick={()=>{
                                       submitVehicle(
                                        new FormData(formRef.current)
                                       );
                                    }}> {isSaving? <RotatingProgress/>:<FontAwesomeIcon icon={faSave}/>} {isSaving?'Saving changes':'Save changes'} </button>
                                 </div>
                            </div>
    
                            </div>
    
                            </div>
    
                        </div>
                    
                    </div>
    
            </div>
           
        ):<div></div>;

  
    
}


export default EditVehiclePage;
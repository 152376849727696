import { faBicycle, faCar} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function VehicleComponent(props){
    return <div className="col-lg-12 nav-link car-side mb-2" style={{ 
        cursor:'pointer',
        borderRadius:'5px',
       
     }}>



      <div style={{ 
         display:'flex',
         alignItems:'center',
         gap:'5px'
         
       }}>

      <span className="btn btn-default text-center" style={{ 
         height:'39px',
         width:'39px',
         borderRadius:'50%',
         display:'grid',
         placeItems:'center'
         }}>
            {
               (props.vehicle.car_type==='bike')?
                  <FontAwesomeIcon icon={faBicycle}/>: 
                  <FontAwesomeIcon icon={faCar}/>
            }
           
        </span>

       <div style={{ 
         display:'flex',
         flexDirection:'column',
         justifyContent:'space-around',
         alignContent:'center'
        }}>
       <span style={{ 
         fontSize:'10pt',
         fontWeight:'bold'
        }} className="text-center">
          {props.vehicle.plate_number}
        
         
        </span>

        <span>
            <small className="text-center">{props.vehicle.car_name}</small>
            <br />

        </span>
       </div>



      </div>

        

        <div className=" text-center">

         {
            props.vehicle.car_status==='active'?<span className="badge badge-success "> </span>: <span className="badge badge-danger m-2 "> </span>
         }

         <br />

         <small>
            Active
         </small>
        </div>
        
    </div>
}

export default VehicleComponent;
import { useEffect, useState } from 'react';
import '../App.css';
import apiURL from '../values/Strings';
import ReportsSideBar from './partials/ReportsSideBar';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlus, faRefresh } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import LoginCheck from '../utils/LoginCheck';


const AdminsPage =()=>{


    const navigate = useNavigate();


    const [users,setUsers] = useState([]);

    const [isLoaded,setIsLoaded] = useState(false);
    useEffect(()=>{
            if(!isLoaded){
                fetch(`${apiURL}/admin_users`,{
                }).then(response=>response.json()).then(json=>{
                   setUsers(json);
                   setIsLoaded(true);
                });
            }
    });

    var activeUser = window.localStorage.getItem('__fuel_user__');

    useEffect(()=>{
        if (!activeUser) {
            navigate('/login')
        }
    })



    return activeUser?(
        <>
        <LoginCheck/>
        <div className='content m-3'>
        <div className='row'>
                    <ReportsSideBar activePage='users'/>
                    <div className='col-lg-9'>

                        <div className='card m-2'>

                            <div className="card-header">
                                <h5>
                                    All System Users
                                </h5>
                            </div>

                            <div className='card-body'>

                        <div className="row">
                            <div className="col-lg-12">
                                <Link to='/create_user' className='btn btn-default '><FontAwesomeIcon icon={faPlus}/> Create new account</Link>
                                <button className='btn btn-default ml-2 '><FontAwesomeIcon icon={faRefresh}/> Refresh</button>
                            </div>
                        </div>

                        <hr />
                        <table className='table' >
                            <thead>
                                <tr>
                                    <th></th>
                                   
                                    <th>
                                        Name
                                    </th>

                                    <th>
                                        Email
                                    </th>

                                    <th>
                                        Contact
                                    </th>

                                   <th>Role</th>

                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Created At
                                    </th>

                                    <th>
                                       
                                    </th>

                                </tr>
                            </thead>


                            <tbody>

                               {users.map(user=>{
                               
                                return  <tr key={user.user_id}>
                                    
                                    <td>


                                       
                                    </td>
                                    <th>{user.full_name}</th>
                                    <td>{user.contact}</td>
                                    <td>{user.email}</td>
                                    <td>{user.role_name}</td>
                                    <td> <span className={user.status==='active'?'btn-sm btn-success':'btn-sm btn-danger'} >{user.status}</span> </td>
                                    <td>{user.created_at}</td>
                                    <td>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="default" id="dropdown-basic">
                                            Actions
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={()=>{
                                                navigate('/edit_user',{
                                                    state:user
                                                })
                                            }} href="#">Edit</Dropdown.Item>
                                            <Dropdown.Item href="#">Delete</Dropdown.Item>
                                        
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    </td>
                                </tr>
                               })}
                            </tbody>
                        </table>

                            </div>

                        </div>

                    </div>

                </div>

        </div>
        </>
       
    ):<div></div>;
}


export default AdminsPage;
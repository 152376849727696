
import { Link } from 'react-router-dom';
import VehicleComponent from '../../components/vehicle/Vehicle';
import { useEffect, useState } from 'react';
import apiURL from '../../values/Strings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFile, faGasPump,faMap } from '@fortawesome/free-solid-svg-icons';




window.intervalHasBeenSet = false;
window.loadedVehicles = [];
const VehiclesSideBar = ()=>{


   const loadVehicles = ()=>{
      fetch(`${apiURL}/home_vehicles`)
            .then(response=>response.json())
            .then(json=>{

               window.loadedVehicles = json;
              
               setVehicles(json);
               setAllVehicles(json);
            })
   }

   const [vehicles, setVehicles] = useState([]);
   const [allVehicles,setAllVehicles] = useState();
   
      useEffect(()=>{

       
        if (window.intervalHasBeenSet===false) {
            loadVehicles();
            setInterval(()=>{
            loadVehicles();
         },8500);
   
         window.intervalHasBeenSet = true;


        }else{
         setVehicles(window.loadedVehicles)
        }

      });

    return <div className="col-lg-3" style={{ 
      position:'static',
      zIndex:'18',
      marginLeft:'12px',
      marginTop:'12px'
     }}>
       

       <div className='card mb-3 elevation-3'>
        <div className='card-body' style={{
            minHeight:'76vh'
         }}>
           <div className="row">
            <div className="col-lg-12">
            <input className='form-control text-danger'  onChange={(ev)=>{

             var searchResults =  allVehicles.filter(item=>{
               if (item.car_name.toLowerCase().includes(ev.target.value.trim().toLowerCase())) {
                  return item;
               }else if(item.plate_number.toLowerCase().includes(ev.target.value.trim().toLowerCase())){
                  return item;
               }

               });

               setVehicles(searchResults);

            }} placeholder='Search vehicle here..'/>
            </div>
           </div>

           <br />

            <div className="p-1 text-center" style={{ 
              height:'72vh',
              overflowY:'scroll'
             }}>
                  {
                    vehicles.length<1?<span className='text-bold'><small className='text-danger text-bold'>Active vehicles will appear here.</small> </span>:""
                  }
                  {
                   vehicles.map(vehicle=><VehicleComponent key={vehicle.car_id} vehicle={vehicle}/>)
                  }
            </div>

         <div style={{ 
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center'
          }} className='col-lg-12'>

         <Link to={"/home"}  className='col-lg-4 text-center text-danger'>
            <span>
               <FontAwesomeIcon icon={faMap}/>
             </span>
             <br/>
             <small >Maps</small>
          </Link>

          <Link to={"/fuel"} className='col-lg-4 text-center'>
             <span>
               <FontAwesomeIcon icon={faGasPump}/>
             </span>
             <br/>
             <small>Fuel</small>
          </Link>

          <Link to={"/routes"} className='col-lg-4 text-center'>
             <span>
               <FontAwesomeIcon icon={faFile}/>
             </span>
             <br/>
             <small>Reports</small>
          </Link>

         </div>

        </div>
       </div>
    </div>
}
export default VehiclesSideBar;
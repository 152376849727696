import { useEffect,useState } from 'react';
import '../App.css';
import apiURL from '../values/Strings';
import ReportsSideBar from './partials/ReportsSideBar';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBicycle, faCar, faPlus, faRefresh } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import LoginCheck from '../utils/LoginCheck';


const VehiclesPage =()=>{


    const navigate = useNavigate();


    const [vehicles,setVehicles] = useState([]);

    const [isLoaded,setIsLoaded] = useState(false);
    useEffect(()=>{

            
            if(!isLoaded){
                fetch(`${apiURL}/api_cars`,{
                }).then(response=>response.json()).then(json=>{
                   setVehicles(json);
                   setIsLoaded(true);
                });
            }
            
    });

    var activeUser = window.localStorage.getItem('__fuel_user__');

    useEffect(()=>{
        if (!activeUser) {
            navigate('/login')
        }
    })


    return activeUser? (
        <>
        
        <div className='content m-3'>
        <div className='row'>
                    <ReportsSideBar activePage='vehicles'/>
                    <div className='col-lg-9'>

                        <div className='card m-2'>

                            <div className="card-header">
                                <h5>
                                     Vehicles
                                </h5>
                            </div>


                            <div className='card-body'>

                        <div className="row">
                            <div className="col-lg-12">
                                <Link to='/add_vehicle' className='btn btn-default '><FontAwesomeIcon icon={faPlus}/> Register Vehicle</Link>
                                <button className='btn btn-default ml-2 '><FontAwesomeIcon icon={faRefresh}/> Refresh</button>
                            </div>
                        </div>

                        <br />

                        {/* <p className='alert alert-default  text-bold' style={{ 
                            backgroundColor:'lightgrey'
                         }}>

                           <small>
                           This table shows all vehicles that have been registered into the system.
                           </small>

                        </p> */}

                        <hr />
                        <table className='table' >
                            <thead>
                                <tr>
                                    <th></th>
                                   
                                    <th>
                                        No. Plate
                                    </th>

                                    <th>
                                        Name
                                    </th>

                                    <th>
                                        Fuel
                                    </th>

                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Created At
                                    </th>

                                    <th>
                                       
                                    </th>

                                </tr>
                            </thead>


                            <tbody>

                               {vehicles.map(vehicle=>{
                               
                                return  <tr key={vehicle.car_id}>
                                    
                                    <td>
                                        <span style={{ 
                                            backgroundColor:'lightgrey',
                                            borderRadius:'50%',
                                            height:'34px',
                                            width:'34px',
                                            display:'grid',
                                            placeItems:'center'
                                         }}>

                                            <FontAwesomeIcon icon={vehicle.car_type==='car'?faCar:faBicycle} />

                                        </span>
                                    </td>
                                    <td>
                                    {vehicle.plate_number}
                                    </td>
                                    <td>{vehicle.car_name}</td>
                                    
                                    <td>{vehicle.car_fuel_type}</td>
                                    <td className='text-bold'> <span className={vehicle.car_status==='active'?'btn-sm text-success':'btn-sm text-danger'} >{vehicle.car_status}</span> </td>
                                    <td>{vehicle.car_created_at}</td>
                                    <td>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="default" id="dropdown-basic">
                                            Actions
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={()=>{
                                                navigate('/edit_vehicle',{
                                                    state:vehicle
                                                })
                                            }} href="#">Edit</Dropdown.Item>
                                            <Dropdown.Item href="#">Delete</Dropdown.Item>
                                        
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    </td>
                                </tr>
                               })}
                            </tbody>
                        </table>

                            </div>

                        </div>

                    </div>
                


                    
                </div>

        </div>
        </>
       
    ):<div></div>;
}


export default VehiclesPage;
import '../App.css';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import React, { useRef, useState, useEffect } from 'react';
import  '../styles/Map.css';
import apiURL from '../values/Strings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBicycle,  faCar, faClose, faFile, faMapMarker, faPlus, faReceipt, faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons';
import ReactDOM  from 'react-dom';
import CalculatorSideBar from './partials/CalculatorSideBar';
import LoginCheck from '../utils/LoginCheck';
import { json, useNavigate } from 'react-router-dom';
import apiKey from '../values/SearchKey';

mapboxgl.accessToken = 'pk.eyJ1Ijoia2lzYWt5ZW5qIiwiYSI6ImNsOHd2OXZkbDAwZ2Qzcmx5MHdmcWU0ZTAifQ.5HXwrE0hrV8ASva9zlAAhQ';

var mapMarkers = [];

window.employeesLoaded= false;
window.carsLoaded = false;

var storedLats = [];

window.loadedCalCars  = [];





const FuelPopup = (props)=>{


    const [employees,setDrivers] = useState([]);

    const [isSaving,setIsSaving] = useState(false);

    const formRef =  useRef();

   



    useEffect(()=>{


        if (window.employeesLoaded===false) {
            fetch(`${apiURL}/employees`).then(response=>{
                return response.json();
            }).then(json=>{
                window.employeesLoaded=true;
                setDrivers(json);
    
            }).catch(err=>{
                console.error(err);
            });
        }

      

    })


    function addExpenditure(ev){


        ev.preventDefault();


        setIsSaving(true);

        fetch(`${apiURL}/add_fuel_issuing`,{
            method:'post',
            body:new FormData(formRef.current)
        }).then(response=>{
            if (response.status===200) {

                ev.target.reset();
                window.showToast('Fuel record added successfully',false);


            }else{
                window.showToast('Something went wrong',true);
            }
        }).catch(err=>{
            console.error(err)
        }).finally(()=>{
            setIsSaving(false);
        })

    }
   
    return <form onSubmit={addExpenditure}  ref={formRef}
    className='col-lg-6 form-popup' >
        <div style={{ 
            height:'min-content',
            zIndex:'15'
         }}>


            <div>
            <span className='float-right'


            onClick={(ev)=>{
              let popup =   window.document.querySelector('.form-popup');
              popup.style.display='none';
            }}
            
            
             style={{ 
                cursor:'pointer'
             }}>
                <FontAwesomeIcon icon={faClose}/>
            </span>
            <h5 className='text-bold'>Issue Fuel</h5>
           
            </div>
           
            <hr/>



            <div className="row">

                <div className="form-group col-lg-12">
                    <label htmlFor="">Driver:</label>

                    <select required name="driver_id" className='form-control' id="">
                        <option value="">Select Driver</option>

                        {employees.map(emp=> <option value={emp.employee_id}>{emp.name}</option>)}
                    </select>
                </div>

                <div className="form-group col-lg-12">
                    <label htmlFor="">Vehicle Used:</label>

                    <select required name="vehicle_id" className='form-control' id="">
                        <option value="">Select Vehicle</option>

                        {window.loadedCalCars.map(v=> <option value={v.car_id}>({v.plate_number}) {v.car_name}</option>)}
                    </select>
                </div>


                <div className="form-group col-lg-12">
                    <label htmlFor="">Issued By:</label>

                    <input required name="issued_by" placeholder='Name of issuerer..' className='form-control' id=""/>
                        
                </div>

                <div className="form-group col-lg-12">
                    <label htmlFor="">Amount:</label>
                    <input required name="amount" placeholder='Amount UGX' type='number' className='form-control' id=""/>
                      
                </div>

                <div className="form-group col-lg-12">
                    <label htmlFor="">Issue Date:</label>
                    <input required name="date" placeholder='Amount UGX' type='date' className='form-control' id=""/>
                      
                </div>




            </div>


            <button disabled={isSaving} className='btn btn-danger form-control'> {isSaving===true?"Saving..":<FontAwesomeIcon icon={faPlus}/> }</button>
           
        </div>

        <br/>

      
    </form>
}


const FuelCalculatorPage =()=>{

    const [vehicles, setVehicles] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const [searhedLocations,setSearchedLocations] = useState([]);

    const suggestionsRef = useRef();

    const searchInputRef = useRef();

    const navigate = useNavigate();


    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(29.98112349);
    const [lat, setLat] = useState(0.36341234);
    const [zoom, setZoom] = useState(6);

    const [srcLngLat,setSrcLngLat] = useState({});
    const [destLngLat,setDestLngLat] = useState({});
    const [isMounted,setIsMounted] = useState(false);

    useEffect(() => {
        if (map.current) return; // initialize map only once
            map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [lng, lat],
            zoom: zoom
            });

            if(!isMounted){
                map.current.addControl(new mapboxgl.NavigationControl(), "top-right");
                setIsMounted(true);
            }


            loadVehicles();

      });

      function isEmptyObj(obj){
        for(var i in obj){
            return false;
        }
        return true;
      }

      function loadVehicles(){
        if (window.loadedCalCars.length>0) {
            setVehicles(window.loadedCalCars)
        }else{
            fetch(`${apiURL}/get_fuel_vehicles`)
            .then(response=>response.json())
            .then(json=>{
               setIsLoaded(true);
               setVehicles(json);
               window.loadedCalCars = json;
            })
        }
      }

      function clearMap(){
         mapMarkers.forEach(marker=>{
         marker.remove();
         });
         mapMarkers = [];
         setDestLngLat({});
         setSrcLngLat({});
         storedLats=[];
      }

      function createPoint(ev){
                // Create a React ref
                const ref = React.createRef();

                // Create a new DOM node and save it to the React ref
                ref.current = document.createElement("div");

                // Render a Marker Component on our new DOM node
                  ReactDOM.render(
                    <Marker data={{  }} type="" />,
                    ref.current
                );

            var marker =   new mapboxgl.Marker(ref.current).setLngLat([ev.lngLat.lng,ev.lngLat.lat])
                .addTo(map.current);

            mapMarkers.push(marker);
      }




      function createSearchedPoint(lng,lat){
        // Create a React ref
        const ref = React.createRef();

        // Create a new DOM node and save it to the React ref
        ref.current = document.createElement("div");

        // Render a Marker Component on our new DOM node
          ReactDOM.render(
            <Marker data={{  }} type="" />,
            ref.current
        );

    var marker =   new mapboxgl.Marker(ref.current).setLngLat([lng,lat])
        .addTo(map.current);

    mapMarkers.push(marker);

}

      useEffect(() => {
        if (!map.current) return; 

        // Add navigation control (the +/- zoom buttons)
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });

           
            map.current.on('click',(ev)=>{

                    if (!storedLats.includes(ev.lngLat.lat)) {


                        console.log(ev.lngLat);
                            if (isEmptyObj(srcLngLat)&&mapMarkers.length<1) {
                                setSrcLngLat(ev.lngLat)
                                    createPoint(ev);
                            }else if(isEmptyObj(destLngLat)&&mapMarkers.length<2){

                                setDestLngLat(ev.lngLat);
                                createPoint(ev);
                            }
                            storedLats.push(ev.lngLat.lat)
                    }

  });
 });




const Marker = (props) => {
   
    return (
    <div className='text-center' style={{ 
        display:'grid',
        placeItems:'center'
     }}>
  
      <button style={{ 
        boxShadow:'0px 0px 6px grey',
     }} className="custom-marker">

        <span className='text-danger'> <FontAwesomeIcon icon={faMapMarker} /> </span>
      </button>
     
      </div>
    );
};



function performSearch(query){
    let search = `https://api.locationiq.com/v1/autocomplete?key=${apiKey}&q=${query}&limit=5&dedupe=1`;
    fetch(
        search,     
    ).then(resp=>resp.json()).then(json=>{
        console.log(json);

        //setSearchedLocations(json);

        suggestionsRef.current.innerHTML=``;

        json.forEach(data=>{


            let suggestion = window.document.createElement('div');

            suggestion.classList.add('suggestion-item');
            suggestion.classList.add('mb-1');

            suggestion.innerHTML = ` <span>${data.display_name}</span>`;

            suggestion.addEventListener('click',ev=>{

                    ev.lngLat = {
                        lng:data.lon,
                        lat:data.lat
                    };

                   

                    if (isEmptyObj(srcLngLat)&&mapMarkers.length<1) {
                            setSrcLngLat(ev.lngLat)
                            createSearchedPoint(ev.lngLat.lng,ev.lngLat.lat);
                    }else if(isEmptyObj(destLngLat)&&mapMarkers.length<2){
                        setDestLngLat(ev.lngLat);
                        createSearchedPoint(ev.lngLat.lng,ev.lngLat.lat);
                    }else{
                        window.showToast("Two points already chosen! You may clear the map first")
                    }

                    let box = window.document.querySelector('.suggestion-box');
                    box.style.display='none';

                    searchInputRef.current.value = `${data.display_name}`;
            });


            suggestionsRef.current.appendChild(suggestion);


        })

    }).catch(err=>{
        console.error(err);
        window.showToast('Something went wrong');
    });

}

const SearchBar=(props)=>{

  


    return <div className='search-bar col-lg-12'>

        <div className='suggestion-container' style={{ 
            padding:'5px',
            backgroundColor:'white',
            display:'flex',
            alignItems:'center',
         }}>
       <input ref={searchInputRef} type="text" onChange={(ev)=>{

        let box = window.document.querySelector('.suggestion-box');


        if (box!==null) {

            if (ev.target.value.trim()==='') {
                box.style.display='none';
                return;
            }else{
                box.style.display='block';
            }
            
        }

        if (ev.target.value.trim().length>3) {
            performSearch(`${ev.target.value} Uganda`);
        }

       }} className='form-control' placeholder='Type your search here..' />

       <span className='ml-1'>
       <FontAwesomeIcon icon={faSearch}/>
       </span>


       </div>


        
            <div ref={suggestionsRef} className='bg-white p-2 suggestion-box' style={{ 
                display:'none',
                maxHeight:'65vh',
                overflowY:'scroll'
             }} >
    
            
            </div>
    
        
       

       
    
    
    
    </div>
}


var activeUser = window.localStorage.getItem('__fuel_user__');


    useEffect(()=>{
        if (!activeUser) {
            navigator('/login');
        }
    })

    return activeUser? (

        <div className='content'>

        <SearchBar/>
        
         <FuelPopup/>
        <div className='row'>
                    <CalculatorSideBar vehicles={vehicles}   srcLngLat={srcLngLat} destLngLat={destLngLat} />
                    <div className='col-lg-12' style={{ 
                        position:'absolute',
                        margin:'0px',
                        height:'100%',
                        padding:'0px'
                        
                     }}>

                        <button className='btn btn-danger btn-flat'
                        
                        onClick={clearMap}
                        
                        style={{ 
                            zIndex:'23',
                            position:'absolute',
                            bottom:'90px',
                            
                            right:'15px',
                            borderRadius:'50%',
                            height:'42px',
                            width:'42px',
                            boxShadow:'0px 0px 4px red'
                            
                         }}><FontAwesomeIcon icon={faRefresh} /></button>


                        <button className='btn btn-success btn-flat'
                        
                        onClick={(ev)=>{
                            let popup = window.document.querySelector('.form-popup');
                            popup.style.display='block';
                        }}
                        
                        style={{ 
                            zIndex:'23',
                            position:'absolute',
                            bottom:'148px',
                            right:'15px',
                            borderRadius:'50%',
                            height:'42px',
                            width:'42px',
                            boxShadow:'0px 0px 4px green'
                            
                         }}><FontAwesomeIcon icon={faPlus} /></button>

                      <button className='btn btn-info btn-flat'
                        
                        onClick={(ev)=>{
                            navigate("/fuel_reports")
                        }}
                        
                        style={{ 
                            zIndex:'23',
                            position:'absolute',
                            bottom:'210px',
                            right:'15px',
                            borderRadius:'50%',
                            height:'42px',
                            width:'42px',
                            boxShadow:'0px 0px 4px green'
                            
                         }}><FontAwesomeIcon icon={faFile} /></button>


                         
                        <div className='map-container' ref={mapContainer} style={{ 
                            height:'100%',
                        }}></div>
                        

                    </div>
                </div>

        </div>
       
    ):<div>

    </div>;
}

export default FuelCalculatorPage;
import { useNavigate } from "react-router-dom";

const LoginCheck = ()=>{
    const navigator = useNavigate();
    var user = window.localStorage.getItem('__fuel_user__');
    if(!user){
        navigator('/login');
    }
}


export default LoginCheck;